import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import { LogikFieldsEnum, SerialTypeEnum } from '../../../../shared/logik/constants/fields.constant';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import { FieldUpdate, SetFieldUpdate, getFieldValueFromArray } from '../../../../shared/logik/models/field.model';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import { ConfiguratorAPI } from '../../../../shared/services/configurator.service';
import { selectIsCheckedOut } from '../../../checkout/Checkout.slice';
import { listPayments } from '../../../checkout/thunk/listPayments';
import { PrebuiltStatus } from '../../../prebuilts/prebuilt.model';
import { PrebuiltsAPI } from '../../../prebuilts/prebuilts.service';
import { setActionType, setUUID, WidgetState } from '../../Widget.slice';
import { fetchPricingDetails } from '../basic/fetchPricingDetails';
import { fetchSets } from '../basic/fetchSets';
import { postProcessConfiguration } from '../basic/postProcessConfiguration';
import { setUserProfile } from './setUserProfile';
export type EditInitConfigurationParams = {
    updates?: (FieldUpdate | SetFieldUpdate)[];
};
import { getDefaultLayout } from '../../../../shared/logik/utils/layouts.utils';
import { fetchTooltips } from '../basic/fetchTooltips';
import { getOrderDateTimeUTCUpdate, getOrderDateUpdate, getUTCInISO } from '../../../wizard/wizard.helpers';
import { PreownedAPI } from '../../../preowned/preowned.service';
import { removeDeprecatedDiscounts } from '../actions/removeDeprecatedDiscounts';
import { updateConfiguration } from '../basic/updateConfiguration';

export const editInitConfiguration = createAsyncThunk<
    Configuration,
    EditInitConfigurationParams | undefined,
    {
        state: RootState;
    }
>(
    'init/edit',
    async (params, thunkAPI): Promise<Configuration> => {
        const widgetState = thunkAPI.getState().widget;
        const uuid = widgetState.uuid;

        if (uuid) {
            const dispatch = thunkAPI.dispatch;

            const origConfig = await logikApiService.fetchConfiguration(uuid);
            const isOrder = getFieldValueFromArray(origConfig.fields, LogikFieldsEnum.checkedOut) === true;
            const origSiteId = getFieldValueFromArray<string>(origConfig.fields, LogikFieldsEnum.siteId);

            let copyUpdates: FieldUpdate[] = [...(params?.updates ?? [])];

            if (!isOrder) {
                if (!widgetState.init?.actionType) dispatch(setActionType('QuoteEdit'));

                copyUpdates = [
                    {
                        value: 'Customer',
                        variableName: LogikFieldsEnum.userProfile,
                    },
                    ...copyUpdates,
                    getOrderDateTimeUTCUpdate(),
                ];

                if (origSiteId) {
                    copyUpdates.push(getOrderDateUpdate(getUTCInISO(), origSiteId));
                }
            } else {
                if (!widgetState.init?.actionType) dispatch(setActionType('OrderEdit'));

                if (origSiteId) {
                    if (!getFieldValueFromArray(origConfig.fields, LogikFieldsEnum.orderDateTimeUTC)) {
                        let orderDate = getFieldValueFromArray<string>(
                            origConfig.fields,
                            LogikFieldsEnum.orderDateValue,
                        );

                        if (orderDate) {
                            orderDate = String(orderDate);
                            copyUpdates = [
                                ...copyUpdates,
                                {
                                    variableName: LogikFieldsEnum.orderDateTimeUTC,
                                    value: new Date(
                                        Date.UTC(
                                            Number(orderDate.substring(0, 4)),
                                            Number(orderDate.substring(4, 6)) - 1,
                                            Number(orderDate.substring(6, 8)),
                                            12,
                                            0,
                                            0,
                                        ),
                                    ).toISOString(),
                                },
                            ];
                        }
                    }
                }
            }
            const configuration = await logikApiService.copyConfiguration(uuid, copyUpdates);

            const prebuiltIsMasterConfig = getFieldValueFromArray(
                configuration.fields,
                LogikFieldsEnum.prebuiltIsMasterConfig,
            );
            if (prebuiltIsMasterConfig === true) {
                throw new Error('Editing master configuration is forbidden');
            }

            const prebuiltSerial = getFieldValueFromArray(configuration.fields, LogikFieldsEnum.prebuiltSerialNumber);

            if (!!prebuiltSerial) {
                const inventoryType = getFieldValueFromArray(configuration.fields, LogikFieldsEnum.serialType);
                const isOrder = getFieldValueFromArray(configuration.fields, LogikFieldsEnum.checkedOut) === true;
                let inventory;

                const siteId = getFieldValueFromArray<string>(configuration.fields, LogikFieldsEnum.prebuiltSiteId);
                if (!siteId) {
                    throw new Error('Prebuilt site id is missing from configuration');
                }

                const inventoryUpdates: FieldUpdate[] = [];
                const serialType = getFieldValueFromArray<string>(configuration.fields, LogikFieldsEnum.serialType);
                const warehouseId = getFieldValueFromArray<string>(configuration.fields, LogikFieldsEnum.warehouse);

                if (inventoryType === SerialTypeEnum.preowned) {
                    inventory = await PreownedAPI.getPreownedPublicInfoBySerial(siteId, prebuiltSerial as string);
                    if (!serialType) {
                        inventoryUpdates.push({
                            variableName: LogikFieldsEnum.serialType,
                            value: SerialTypeEnum.preowned,
                        });
                    }
                } else if (inventoryType === SerialTypeEnum.prebuilt || !inventoryType) {
                    inventory = await PrebuiltsAPI.getPrebuiltPublicInfoBySerial(siteId, prebuiltSerial as string);
                    if (!serialType) {
                        inventoryUpdates.push({
                            variableName: LogikFieldsEnum.serialType,
                            value: SerialTypeEnum.prebuilt,
                        });
                    }
                }

                if (inventory?.warehouse?.warehouseId && inventory?.warehouse?.warehouseId !== warehouseId) {
                    inventoryUpdates.push({
                        variableName: LogikFieldsEnum.warehouse,
                        value: inventory.warehouse.warehouseId,
                    });
                }

                if (inventoryUpdates.length > 0) {
                    await dispatch(
                        updateConfiguration({
                            uuid: configuration.uuid,
                            updates: inventoryUpdates,
                        }),
                    );
                }

                if (inventory?.statusId === PrebuiltStatus.Sold && !isOrder) {
                    throw new Error('This prebuilt has been already sold');
                }

                if (!inventory?.masterConfigurationId) {
                    throw new Error('Master config has been deleted');
                }

                const prebuiltMasterConfigUUID = getFieldValueFromArray(
                    configuration.fields,
                    LogikFieldsEnum.prebuiltMasterConfigUUID,
                );

                if (!!prebuiltMasterConfigUUID) {
                    if (inventory?.masterConfigurationId) {
                        const prebuiltConfig = await ConfiguratorAPI.getConfigurationByMasterId(
                            inventory.masterConfigurationId,
                        );

                        if (prebuiltConfig && prebuiltConfig.logikId !== prebuiltMasterConfigUUID) {
                            throw new Error('Prebuilt configuration has changed. You need to start over');
                        }
                    }
                }
            }

            const layoutUrl = getDefaultLayout(configuration)?.url;
            if (layoutUrl) thunkAPI.dispatch(fetchTooltips({ layoutUrl }));
            await dispatch(postProcessConfiguration({ configuration, skipRelatedPulls: true }));

            await dispatch(setUserProfile(configuration.uuid));

            await Promise.all([
                dispatch(
                    fetchSets({
                        uuid: configuration.uuid,
                        sets: configuration.relatedChanges?.filter((ch) => ch.type === 'SET').map((ch) => ch.key) ?? [],
                    }),
                ),
                dispatch(fetchPricingDetails(configuration.uuid)),
            ]);

            await dispatch(removeDeprecatedDiscounts({ configuration }));
            dispatch(setUUID(configuration.uuid));

            if (selectIsCheckedOut(thunkAPI.getState())) {
                const masterConfigurationId = thunkAPI.getState().widget.masterConfigurationId;
                if (masterConfigurationId) {
                    await thunkAPI.dispatch(listPayments(masterConfigurationId));
                }
            }
            return configuration;
        } else {
            return Promise.reject('Invalid UUID');
        }
    },
    {
        condition: (_, { getState }) => !!getState().widget.uuid,
    },
);

export const addEditInitConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(editInitConfiguration.pending, (state) => {
            state.loading = true;
        })
        .addCase(editInitConfiguration.fulfilled, (state, action) => {
            state.productId = action.payload.fields.find((f) => f.variableName === LogikFieldsEnum.product)
                ?.value as string;

            state.loading = false;
        })
        .addCase(editInitConfiguration.rejected, (state) => {
            state.loading = false;
        });
};
